<template>
  <v-main>
    <div class="main-content mb-0">

   
    <div class="header pa-0 align-center">
      
      <v-col class="column-title">
        
        <div class="column-title__item">
          <back-button />
      </div>
        <div class="column-title__item " style="font-size: 24px; font-weight: bold; ">
          <!-- <span>
          {{ $i18n.t('player_info') }} 
              
            </span> -->
          
        </div>
        <h4 v-if="player.id" class="column-title__item column-title__item_info">
          <div class="info-title white-space">
            <div v-if="player.last_visit_ip">
              <span class="c-text">{{ $t('last_visit') }}: </span>
              <span class="c-red">{{ player.last_visit_ip }}</span>
            </div>
            
          </div>
          <div class="info-title white-space">
            <div v-if="player.country_code">
              
              <span class="c-text">{{ $t('country') }}: </span>
              <span class="c-red">{{ player.country_code }}</span>
            </div>
            
          </div>
          <div class="info-title white-space">
            <div v-if="player.id">
              
              <span class="c-text">ID: </span>
              <span class="c-red">{{ player.id }}</span>
            </div>
          </div>
                 <v-badge
                 
                 bordered
                 color="error"
                 :color="getBadgeColor"
                 overlap
                 class="d-block"
               >
            <div class="player-logo" >
              <img v-if="player.avatar"
               :src="`https://minio.takemybet.pro/avatars/${player.avatar.image}`" alt="avatar">
            </div>
          </v-badge>
        </h4>
      </v-col>
    </div>
    <v-overlay v-if="loadingFlag" opacity="1" class="custom-overlay" color="transparent" z-index="500">
      <v-progress-circular
          indeterminate
          color="var(--main-flamingo)"
          size="150"
      >
        <span class="font-weight-bold">{{ $i18n.t('loading') }}...</span>
      </v-progress-circular>
    </v-overlay>
    <!-- Payments info -->
    <!-- <v-row justify="center">
      <div class="item-card">
        <v-card class="elevation-0 card-view _total">
          <v-card-text class="py-2 px-1 d-flex" style="font-size: 20px">
            <v-row class="box-column">
              <v-col class="d-flex align-center">
                <div class="d-flex align-center justify-center box-icon" >
                  <v-icon color="var(--white)">mdi-cash-multiple</v-icon>
                </div>
              </v-col>
            </v-row>
            <v-row class="d-flex flex-column c-white ml-3" >
                <v-col class="d-flex align-start flex-column justify-center" >
                  <span class=" font-weight-bold">{{  (
                    (player.balance ? player.balance : 0) +
                    (player.bonus_balance ? player.bonus_balance : 0)
                  ).toFixed(2) }} {{ player.currency }}</span>
                  <span style="font-size:16px">{{ $i18n.t('total_balance') }}</span>
                </v-col>
            </v-row>
            
          </v-card-text>
        </v-card>
      </div>
      <div class="item-card">
        <v-card class="elevation-0 card-view _cashouts">
          <v-card-text class="py-2 px-1 d-flex" style="font-size: 20px">
            <v-row class="box-column">
              <v-col class="d-flex align-center">
                <div class="d-flex align-center justify-center box-icon" >
                  <v-icon color="var(--white)">mdi-cash-check</v-icon>
                </div>
              </v-col>
            </v-row>
            <v-row class="d-flex flex-column c-white ml-3" >
                <v-col class="d-flex flex-column align-start justify-center" >
                  <span class="font-weight-bold">{{ player && player.sum_available_for_withdraw && player.sum_available_for_withdraw !== null  ? player.sum_available_for_withdraw : 0 }} {{ player.currency }}</span>
                  <span class="text-left" style="font-size:16px">{{ $i18n.t('cashout_balance') }}</span>
                </v-col>
            </v-row>
            
          </v-card-text>
        </v-card>
      </div>
      <div class="item-card">
        <v-card class="elevation-0 card-view _bonus">
          <v-card-text class="py-2 px-1 d-flex" style="font-size: 20px">
            <v-row class="box-column">
              <v-col class="d-flex align-center">
                <div class="d-flex align-center justify-center box-icon" >
                  <v-icon color="var(--white)">mdi-gift</v-icon>
                </div>
              </v-col>
            </v-row>
            <v-row class="d-flex flex-column c-white ml-3" >
                <v-col class="d-flex flex-column align-start justify-center" >
                  <span class="font-weight-bold">
                    {{ player.bonus_balance ? player.bonus_balance.toLocaleString() : 0 }} {{ player.currency }}

                  </span>
                  <span class="text-left" style="font-size:16px">{{ $i18n.t('bonus_balance') }}</span>
                </v-col>
            </v-row>
            
          </v-card-text>
        </v-card>
      </div>
      <div class="item-card">
        <v-card class="elevation-0 card-view _wager">
          <v-card-text class="py-2 px-1 d-flex" style="font-size: 20px">
            <v-row class="box-column">
              <v-col class="d-flex align-center">
                <div class="d-flex align-center justify-center box-icon" >
                  <v-icon color="var(--white)">mdi-cash-lock</v-icon>
                </div>
              </v-col>
            </v-row>
            <v-row class="d-flex flex-column c-white ml-3" >
                <v-col class="d-flex flex-column align-start justify-center" >
                  <span class="font-weight-bold">
                      <span>{{ player.wagering
                        && player.wagering !== null ? player.wagering : 0 }} /
                        {{ player && player.wager_amount !== null ? player.wager_amount : 0 }}
                      </span>
                      <span class="ml-1">{{ player.currency }}</span>
                  </span>
                  <span class="text-left" style="font-size:16px">{{ $i18n.t('wager_playthrough') }}</span>
                </v-col>
            </v-row>
            
          </v-card-text>
        </v-card>
      </div>
      <div class="item-card">
        <v-card class="elevation-0 card-view _deposits">
          <v-card-text class="py-2 px-1 d-flex" style="font-size: 20px">
            <v-row class="box-column">
              <v-col class="d-flex align-center">
                <div class="d-flex align-center justify-center box-icon" >
                  <v-icon color="var(--white)">fa-cash-register</v-icon>
                </div>
              </v-col>
            </v-row>
            <v-row class="d-flex flex-column c-white ml-3" >
                <v-col class="d-flex flex-column align-start justify-center" >
                  <span class="font-weight-bold">
                      {{ player && player.deposits_sum
                        && player.deposits_sum
                        !== null && player.deposits_sum
                        !== 0 ? player.deposits_sum
                        : 0 }} {{ player.currency }}
                  </span>
                  <span class="text-left" style="font-size:16px">{{ $i18n.t('deposits') }}</span>
                </v-col>
            </v-row>
            
          </v-card-text>
        </v-card>
      </div>
      <div class="item-card">
        <v-badge
        bordered
        color="error"
        icon="mdi-lock"
        overlap
        :value="successWithdrawBlock"
        class="d-block"
      >
        <v-card class="elevation-0 card-view _cashouts">
          <v-card-text class="py-2 px-1 d-flex" style="font-size: 20px">
            <v-row class="box-column">
              <v-col class="d-flex align-center">
                <div class="d-flex align-center justify-center box-icon" >
                  <v-icon color="var(--white)">mdi-bank</v-icon>
                </div>
              </v-col>
            </v-row>
            <v-row class="d-flex flex-column c-white ml-3" >
                <v-col class="d-flex flex-column align-start justify-center" >
                  <span class="font-weight-bold">
                    {{ player && player.withdraws_sum
                      && player.withdraws_sum
                      !== null && player.withdraws_sum
                      !== 0 ? player.withdraws_sum
                      : 0 }} {{ player.currency }}
                  </span>
                  <span class="text-left" style="font-size:16px">{{ $i18n.t('withdrawals') }}</span>
                </v-col>
            </v-row>
            
          </v-card-text>
        </v-card>
        </v-badge>
      </div>
    </v-row> -->
     <!-- General player info -->
    <v-row style="justify-content: center;" v-if="!errorFlag">
      <v-col xl="4" md="4" sm="12" style="min-width: 250px">
        <Card class="card-custom" style="height: 100%">
          <template #title>
            <h4>{{ $i18n.t('general_info') }}</h4>
          </template>
          <template #content>
            <v-row align="center" v-if="isAgentPlayer">
              <v-col class="text-left">
                {{ $i18n.t('agent_id') }}:
              </v-col>
              <v-col class="text-right">
                <span v-if="player && !player.agent_id">
                  ---
                </span>
                <div v-else>
                  {{ player.agent_id }} 
                </div>
                <!-- <router-link v-else :to="`/player/${player.agent_id}`">
                  {{ player.agent_id }} 
                </router-link> -->
              </v-col>
            </v-row>
            <v-row align="center" v-else>
              <v-col class="text-left">
                {{ $i18n.t('referral_id') }}:
              </v-col>
              <v-col class="text-right">
                <span v-if="player && !player.partner_id">
                  ---
                </span>
                <router-link v-else :to="`/player/${player.partner_id}`">
                  {{ player.partner_id }} 
                </router-link>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col class="text-left">
                ID:
              </v-col>
              <v-col class="text-right">
                {{ player.id }}
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col class="text-left">
                {{ $i18n.t('username') }}:
              </v-col>
              <v-col class="text-right">
                {{ !player.username ? '---' : player.username }}
              </v-col>
            </v-row>
            <v-row align="center" v-if="!isAgentPlayer">
              <v-col class="text-left">
                {{ $i18n.t('player_status') }}:
              </v-col>
              <v-col class="text-right">
                {{ !player.lvl_name ? '---' :  player.lvl_name }}
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col class="text-left d-flex align-center">
                <v-icon color="var(--main-flamingo)">
                  fas fa-envelope-open-text
                </v-icon>
                <span class="ml-1">{{ $i18n.t('email') }}:</span>
              </v-col>
              <v-col class="text-right">
                {{ !player.email ? '---' : player.email }}
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col class="text-left d-flex align-center" style="white-space: nowrap">
                <v-icon color="var(--main-flamingo)">
                  fa fa-calendar
                </v-icon>
                <span class="ml-1">{{ $i18n.t('registration_date') }}:</span>
              </v-col>
              <v-col class="text-right">
                {{ player.created_at ? new Date(player.created_at).toLocaleDateString() : '---' }}
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col class="text-left d-flex align-center">
                <v-icon color="var(--main-flamingo)">
                  fa fa-handshake
                </v-icon>
                <span class="ml-1">{{ $i18n.t('affiliate') }}:</span>
              </v-col>
              <v-col class="text-right">
                <template v-if="player && player.partner_id">
                  <router-link :to="`/player/${player.partner_id}`">
                    {{ player.partner_id }} 
                  </router-link>
                </template>
                <template v-else-if="player && player.agent">
                  <router-link v-if="player.agent.id !== admin.id" :to="{ name: 'agents', params: { login: player.agent.id } }">
                    {{ player.agent.login }}
                  </router-link>
                  <div v-else>
                    <span >{{ player.agent.login }} </span>
                    <span class="c-red">
                      ({{ $i18n.t('you') }})
                    </span>
                  </div>
                </template>
                <span v-else>
                  ---
                </span>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col class="text-left d-flex align-center">
                <v-icon color="var(--main-flamingo)">
                  phone
                </v-icon>
                <span class="ml-1">{{ $i18n.t('phone2') }}:</span>
              </v-col>
              <v-col class="text-right">
                <div class="d-flex align-center justify-end">
                 <span>{{ player || player.tel || player.tel === null || player.tel === '' ? '---' : '+38' + player.tel }}</span>
                 <!-- <PlayerPhoneWidget
                      :player="player"></PlayerPhoneWidget>  -->
               </div>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col class="text-left">
                {{ $i18n.t('status') }}:
              </v-col>
              <v-col class="text-right" v-if="player && player.id">
                <span class="custom-badge"
                :class="getAccountStatusClass(player.block, player.block_end)"
                >
                  <span>{{ getAccountStatus(player.block, player.block_end) }} </span>
                  <span v-if="!player.block && player.block_end">({{ formatDate(player.block_end) }})</span>
                </span>
              </v-col>
            </v-row>
            </template>
      </Card>
      </v-col>
      <v-col xl="4" md="4" sm="12" style="min-width: 250px;">
        <Card class="card-custom" style="height: 100%">
          <template #title>
            <h4>{{ $i18n.t('additional_info') }}</h4>
          </template>
          <template #content>
            <v-row align="center">
              <v-col class="text-left">
                {{ $i18n.t('name') }}:
              </v-col>
              <v-col class="text-right">
                {{ player.name === null ? '---' : player.name }}
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col class="text-left">
                {{ $i18n.t('surname') }}:
              </v-col>
              <v-col class="text-right">
                {{ player.last_name === null ? '---' : player.last_name }}
              </v-col>
            </v-row>
            <v-row align="center" v-if="!isAgentPlayer">
              <v-col class="text-left">
                {{ $i18n.t('gender') }}:
              </v-col>
              <v-col class="text-right">
                {{ player.sex === null ? '---' : player.sex }}
              </v-col>
            </v-row>
            <v-row align="center" v-if="player && player.address && !isAgentPlayer">
              <v-col class="text-left">
                {{ $i18n.t('country') }}:
              </v-col>
              <v-col class="text-right" >
                {{ player.address.country === null || player.address.country === "" ? '---' : player.address.country }}
              </v-col>
            </v-row>
            <v-row align="center" v-if="player && player.address && !isAgentPlayer">
              <v-col class="text-left">
                {{ $i18n.t('city') }}:
              </v-col>
              <v-col class="text-right">
                {{ player.address.city === null || player.address.city === "" ? '---' : player.address.city }}
              </v-col>
            </v-row>
            <v-row align="center" v-if="player && player.address && !isAgentPlayer">
              <v-col class="text-left">
                {{ $i18n.t('address') }}:
              </v-col>
              <v-col class="text-right">
                {{ player.address.address === null || player.address.address === "" ? '---' : player.address.address }}
              </v-col>
            </v-row>
            <v-row align="center" v-if="!isAgentPlayer">
              <v-col class="text-left d-flex align-center">
                <v-icon color="var(--main-flamingo)">
                  fa fa-calendar
                </v-icon>
                <span class="ml-1">{{ $i18n.t('birth_date') }}:</span>
              </v-col>
              <v-col class="text-right">
                {{ player.birthday === null ? '---' : new Date(player.birthday).toLocaleDateString() }}
              </v-col>
            </v-row>
            </template>
      </Card>
      </v-col>
       <v-col xl="4" md="4" sm="12" style="min-width: 250px">
        <player-payments-card :player="player" :isAgentPlayer="isAgentPlayer" />
      </v-col>
    </v-row>
     <!-- Operations -->
    <!-- <v-row justify="center">
      <div class="item-card">
        <v-btn
            @click="addBalanceModal = true"
            style="text-transform: none"
            class="success-btn success-btn_fw"
          >
      <v-icon class="mr-2">far fa-money-bill-alt</v-icon>
      {{ $i18n.t('change_balance') }}
        </v-btn>
      </div>
      <div class="item-card d-flex align-center justify-center" v-if="player.is_active">
        <v-btn
                    
                    class="main-btn main-btn_fw"
                    style="text-transform: none"
                    @click="blockPlayerWithdraw(true)"
                    v-if="!successWithdrawBlock"
                >
                  {{ $i18n.t('block_withdraw') }}
                </v-btn>
                <v-btn
                    
                    class="success-btn success-btn_fw"
                    @click="blockPlayerWithdraw(false)"
                    v-if="successWithdrawBlock"
                >
                  {{ $i18n.t('unblock_withdraw') }}
                </v-btn>
      </div>
      <div class="item-card">
        <v-btn
        class="main-btn main-btn_fw"
        style="text-transform: none"
                  @click="blockUser(true)"
                  :loading="blockLoading"
                  v-if="player.block === 0"
              >
                <v-icon class="mr-2">fas fa-ban</v-icon>
                {{ $i18n.t('block_forever') }}
              </v-btn>
        <v-btn
                  :class="{'ml-2' : player.block === 0}"
                  class="success-btn success-btn_fw"
                  @click="unblockUser"
                  v-if="[1,2].includes(player.block) || !player.is_active"
                  :loading="blockLoading"
              >
                <v-icon class="mr-2">fas fa-unlock-alt</v-icon>
                {{ $i18n.t('unblock') }}
            </v-btn>
      </div>
      <div class="item-card d-flex align-center justify-center" v-if="player.is_active">
        <v-btn
        class="main-btn"
                    @click="blockUser(false, true)"
                    style="text-transform: none"
                    :loading="blockLoading"
                    :disabled="!banDateTime"
                >
                  <v-icon class="mr-3">schedule</v-icon>
                  {{ $i18n.t('temporary_block') }}
                </v-btn>
        <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <div
              class="input-menu ml-1"
              v-on="on"
              >
              {{ 
                (banDateTime === 0 || banDateTime == '' || banDateTime === null) ? 
                $t('date')
                : $t('to') + ' ' + banDateTime
              }}
              <v-icon>event</v-icon>
            </div>
            
            </template>
            <v-date-picker
              v-model="banDateTime"
              :locale="currentLocale"
              :min="currentDate"
              no-title
              color="var(--main-flamingo)"
              content-class="date"
            ></v-date-picker>
          </v-menu>
      </div>
    </v-row> -->
    <!-- Bonus table -->
    <v-row v-if="!errorFlag && !isAgentPlayer && player.id">
      <v-col>
        <PPBonuses :playerInfo="player" />
      </v-col>
    </v-row>
    <!-- Verification and operations -->
    <v-row v-if="!errorFlag && !isAgentPlayer && player.id" style="justify-content: space-around">
      <v-col xl="6" md="6" sm="12" class="col">
        <PPVerification 
        :playerInfo="player"
        @update-player-info="updateInfo"
        ></PPVerification>
      </v-col>
      <v-col xl="6" md="6" sm="12" cols="12" class="col">
        <PPManagement 
        :playerInfo="player"
        @update-player-info="updateInfo"
        @trigger-loading-compensation="loadingCompensationFlag = true"
        ></PPManagement>
      </v-col>
    </v-row>
  </div>
  <div v-if="!errorFlag" class="px-3 py-1 justify-center">
    <h2 style="color: var(--text-color)">{{ $i18n.t('detailed_info') }}</h2>
  </div>
    <div v-if="!errorFlag && player.id">
      <v-tabs
          grow
          v-model="selectedTab"
          class="main-tabs"
          background-color="var(--surface-ground)"
      >
        <v-tab>
          <v-icon class="mr-2" color="">
            fas fa-money-bill-alt
          </v-icon>
          {{ $i18n.t('finances') }}
        </v-tab>
        <v-tab>
          <v-icon class="mr-2" color="">
            fas fa-dice
          </v-icon>
          {{ $i18n.t('sessions') }}
        </v-tab>
        <v-tab v-if="!isAgent">
          <v-icon class="mr-2" color="">
            comment
          </v-icon>
          {{ $i18n.t('comments') }}
        </v-tab>
        <v-tab v-if="!isAgent">
          <v-icon class="mr-2" color="">
            notifications
          </v-icon>
          {{ $i18n.t('notifications') }}
        </v-tab>
        <v-tab v-if="!isAgent">
          <v-icon class="mr-2" color="">
            lock
          </v-icon>
          {{ $i18n.t('authorizations') }}
        </v-tab>
        <v-tab v-if="!isAgent">
          <v-icon class="mr-2" color="">
            fas fa-table
          </v-icon>
          {{ $i18n.t('balance_corrections') }}
        </v-tab>
        <v-tab v-if="!isAgent">
          <v-icon class="mr-2" color="">
            people
          </v-icon>
          {{ $i18n.t('multi_accounts') }}
        </v-tab>
      </v-tabs>
      <v-tabs-items
          v-model="selectedTab"
      >
        <v-tab-item  :transition="false" :reverse-transition="false">
          <payments-tab 
          :isAgentPlayer="isAgentPlayer"
          :playerId="id" v-if="id !== null"></payments-tab>
        </v-tab-item>
        <v-tab-item  :transition="false" :reverse-transition="false">
          <sessions-tab 
          :player-currency="player.currency"
          :player-id="id"></sessions-tab>
        </v-tab-item>
        <v-tab-item v-if="!isAgent"  :transition="false" :reverse-transition="false">
          <comments-tab :player-id="id"/>
        </v-tab-item>
        <v-tab-item v-if="!isAgent"  :transition="false" :reverse-transition="false">
          <notifications-tab :player-id="id"/>
        </v-tab-item>
        <v-tab-item v-if="!isAgent" :transition="false" :reverse-transition="false">
          <auth-tab :player-id="id"></auth-tab>
        </v-tab-item>
        <v-tab-item v-if="!isAgent" :transition="false" :reverse-transition="false">
          <balance-corrections-tab 
          :loadingTrigger="loadingCompensationFlag"
           @trigger-loading-compensation="loadingCompensationFlag = false"
          :player-id="id"/>
        </v-tab-item>
        <v-tab-item v-if="!isAgent" :transition="false" :reverse-transition="false">
          <multi-accounts-tab :player="this.player"></multi-accounts-tab>
        </v-tab-item>
      </v-tabs-items>
      <!-- <TabView :activeIndex="1">
        <TabPanel>
            <template #header>
              
              <i class="pi pi-money-bill"></i>
              <span class="ml-1">
                {{ $i18n.t('finances') }}</span>
            </template>
            <payments-tab :playerId="id" v-if="id !== null" />
        </TabPanel>
        <TabPanel>
            <template #header>
              <i class="pi pi-box"></i>
              <span class="ml-1">{{ $i18n.t('sessions') }}</span>
            </template>
            <sessions-tab :player-id="id"></sessions-tab>
        </TabPanel>
        <TabPanel>
            <template #header>
              <i class="pi pi-comments"></i>
              <span class="ml-1">{{ $i18n.t('comments') }}</span>
            </template>
            <CommentsTab :player-id="id"/>
        </TabPanel>
        <TabPanel>
            <template #header>
              <i class="pi pi-lock"></i>
              <span class="ml-1">{{ $i18n.t('authorizations') }}</span>
            </template>
            <auth-tab :player-id="id"></auth-tab>
        </TabPanel>
        <TabPanel>
            <template #header>
              <i class="pi pi-table"></i>
              <span class="ml-1">{{ $i18n.t('balance_corrections') }}</span>
            </template>
            <BalanceCorrectionsTab :player-id="id"/>
        </TabPanel>
        <TabPanel>
            <template #header>
              <i class="pi pi-users"></i>
              <span class="ml-1">{{ $i18n.t('multi_accounts') }}</span>
            </template>
            <multi-accounts-tab :player="player" />
        </TabPanel>
    </TabView> -->
    </div>
    <Message :closable="false" class="mx-auto" style="max-width:800px" v-if="errorFlag" severity="error">{{ $i18n.t('failToGetData') }}</Message>
  </v-main>
</template>

<script>
import PlayerPaymentsCard from '@/views/PlayerProfile/PlayerPaymentsCard'
import PaymentsTab from "@/components/Players/PaymentsTab";
import SessionsTab from "@/components/Players/SessionsTab/PlayerGameSessions";
import CommentsTab from "@/components/Players/CommentsTab";
import NotificationsTab from "@/components/Players/NotificationsTab";
import AuthTab from "@/components/Players/AuthTab";
import BalanceCorrectionsTab from "@/components/Players/BalanceCorrectionsTab";
import MultiAccountsTab from "@/components/Players/MultiAccountsTab";
import {mapGetters} from "vuex";
import PlayerPhoneWidget from "@/components/PlayerPhoneWidget";
//========================================================================================================================================================
// PP components
import PPManagement from '@/views/PlayerProfile/PPManagement.vue'
import PPVerification from '@/views/PlayerProfile/PPVerification.vue'
import PPBonuses from '@/views/PlayerProfile/PPBonuses.vue'

export default {
  name: "PlayerInfo",
  components: {
    PlayerPhoneWidget, 
    MultiAccountsTab, 
    BalanceCorrectionsTab, 
    AuthTab, 
    CommentsTab, 
    NotificationsTab, 
    SessionsTab, 
    PaymentsTab,
    PPManagement,
    PPBonuses,
    PPVerification,
    PlayerPaymentsCard,
  },
  props: {
    id: {
      required: true,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      admin: 'admin',
      tokenUser: 'tokenUser',
    }),
    isAgent() {
      return this.tokenUser === "agent";
    },
    isAgentPlayer(){
      return Boolean(this.player.agent_id);
    },
    getBadgeColor(){
        if (this.player.block === 0 && this.player.is_active) {
          if (this.player.is_online) {
            return 'var(--green-500)';
          } else{
            return 'var(--gray-500)';
          }
        } else if (this.player.block === 2) {
          return 'var(--red-500)';
        } else if(this.player.block === 0 && !this.player.is_active) {
          return 'var(--yellow-500)';
        } else{
          return 'var(--green-500)';
        }
    },
  },
  data() {
    return {
      loadingCompensationFlag: false,
      loadingFlag: true,
      errorFlag: false,
      isOpenTable: false,
      selectedTab: null,
      player: {},
      
      
      
      addBonusModal: false,
      selectedBonus: [],
      loadingModalFlag: false,
      blockLoading: false,
      blockDialog: true,
      banDateTime: null,
      today: new Date(),
      currentLocale: this.$i18n.locale || 'en',
      dateMenu: false,
      imageDialog: false,
      
      imageDialogSelectedImg: null,
      bonuses: [],
      mergedBonuses:[],
      fr_bonuses: [],
    }
  },
  beforeRouteEnter(to, from, next){
    next(vm => {
      vm.$store.commit('setPageName', 'player_info')
    });
    },
    async mounted() {
        this.player = await this.$store.dispatch('playerInfo', {id: this.id});
        if ('id' in this.player) {
        } else {
          this.errorFlag = true;
        }
    this.loadingFlag = false;
  },
  methods: {
    getAccountStatus(block, blockEndDate) {
      const currentDate = new Date();
      const endDate = new Date(blockEndDate);

      if (block === 2) {
        return this.$i18n.t("forever_blocked");
      } else if (endDate > currentDate) {
        return this.$i18n.t("temp_blocked");
      } else if (block === 0) {
        return this.$i18n.t("active");
      } else {
        return "NONE";
      }
    },
    formatDate(date) {
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      return date.toLocaleDateString();
    },
    getAccountStatusClass(block, blockEndDate) {
      const currentDate = new Date();
      const endDate = new Date(blockEndDate);
      return block === 2
        ? "_red"
        : endDate > currentDate
        ? "_yellow"
        : block === 0
        ? "_green"
        : "_yellow";
    },
    async updateInfo() {
      this.player = await this.$store.dispatch('playerInfo', {id: this.id});
    },
    getIdFromUrl() {
              const url = window.location.pathname;
              const parts = url.split('/'); 
              return parts[parts.length - 1];
            },
  },
}
</script>

<style lang="scss" scoped>
.item-card{
  padding: 5px;
  @media (max-width:600px){
    flex: 1 1 100%;
  }
}
.player-logo{
  border-radius: 16px;
 width: 60px;
 height: 60px;
 overflow: hidden;
 flex: 0 0 60px;
 img{
  max-width: 100%;
  max-height: 100%;
 }
  
}
.column-title{
  display: flex;
  justify-content:flex-start;
  align-items: center;
  text-align: center;
  @media (max-width:950px){
      flex-direction: column;
  }
  &__item{
   flex: 0 1 33.333%;
   &:first-child{
    justify-content: flex-start;
    display: flex;
   }
  &_info{
    font-size: 24px;
     display: flex;
     align-items: center;
     justify-content: flex-end;
    @media (max-width:768px){
      font-size: 18px;
        flex-direction: column;
    }
    .info-title{
      &:not(:last-child) {
        margin-right: 10px;
  
      }
      span{
        @media (max-width:992px){
            
          &:last-child{
            white-space: normal;
          }
        }
      }
    }
   }
  }
}

.input-menu{
  color: rgba(0,0,0,.87);
  border: 1px solid rgba(0,0,0,.38);
  border-radius: 8px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  cursor: pointer;
  align-items: center;
  position: relative;
  &__menu{
    background-color: var(--white);
    box-shadow: none;
    padding: 10px;
    z-index: 3;
    position: relative;
    width: 100%;
    .v-input{
      margin-top: 8px;
      &:first-child{
        margin-top: 0px;
      }
    }
  }
}
.header{
  @media (max-width:600px){
      flex-direction: column;
  }
}
.custom-overlay{
  background-color: rgb(255 255 255 / 0.3)!important;
      backdrop-filter: blur(10px);
    }
   

</style>
